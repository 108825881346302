import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['reseauParent'];

    typeChoice(e) {
        let RegionRow = this.reseauParentTarget;
        let inputRegion = RegionRow.querySelector('select');
        if(e.target.value === 'RR_TYPE_AGENCE') {
            RegionRow.classList.remove('d-none');
            inputRegion.required = true;
        } else {
            RegionRow.classList.add('d-none');
            inputRegion.required = false;
        }
    }
}
