import Bowser from "bowser";

const LogMain = class {
    browser = null;
    route = null;

    constructor() {
        this.browser = Bowser.parse(window.navigator.userAgent);
    }

    init(route) {
        this.route = route;
        window.onerror = (message , source , lineno , colno, error) => {
            this.reportError(message , source , lineno , colno, error);
        };
    }

    reportError(message, source, lineno, colno, error, typeLabel) {
        if (typeLabel === '' || typeLabel === null || typeLabel === undefined) {
            typeLabel = 'js';
        }
        let stackTrace = null;
        if (error) stackTrace = error.stack;

        let arrayData = {
            'exception': message,
            'type': typeLabel,
            'file': source,
            'nbLine': lineno,
            'stackTrace': 'Browser : '+JSON.stringify(this.browser)+' - Resolution : '+window.screen.width+'x'+window.screen.height+' - '+stackTrace
        };
        let stringData = JSON.stringify(arrayData);
        // let encodedData = { 'error' : btoa(stringData) };
        let encodedData = { 'error' : stringData };

        fetch(this.route, {
            method: 'post',
            body: encodedData,
        })
    }
};

export default new LogMain();
