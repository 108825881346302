import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
    static targets = ['modal'];
    static values = {
        autoOpen: Boolean,
    }
    modal = null;

    connect() {
        if (this.autoOpenValue === true) {
            const sessionName = this.modalTarget.dataset.session;
            if (sessionName !== undefined) {
                if (sessionStorage.getItem(sessionName) !== 'hide') {
                    this.openModal();
                    sessionStorage.setItem(sessionName, 'hide');
                }
            } else {
                this.openModal();
            }
        }
    }

    openModal(event) {
        this.modal = new Modal(this.modalTarget);
        this.modal.show();
    }
}
