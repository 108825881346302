import { Controller } from 'stimulus';
import { Toast } from 'bootstrap';

export default class extends Controller {
    static values = {
        delay: Number,
    }
    toast = null;

    initialize() {
        const delay = this.hasDelayValue ? this.delayValue : 5000;

        this.toast = new Toast(this.element, {
            delay: delay,
        });

    }

    connect() {
        this.toast.show();
    }
}
