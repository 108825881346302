import { Controller } from 'stimulus';
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
export default class extends Controller {
    static targets = ['unlinkModal'];

    connect() {
        let unlinkModal = this.unlinkModalTarget;
        unlinkModal.addEventListener('show.bs.modal', function (event) {
            // Button that triggered the modal
            let button = event.relatedTarget
            // Extract info from data-bs-* attributes
            let userId = button.getAttribute('data-bs-user-id')
            let userName = button.getAttribute('data-bs-user-name')
            let reseauId = unlinkModal.getAttribute('data-reseau-id')
            // If necessary, you could initiate an AJAX request here
            // and then do the updating in a callback.
            //
            // Update the modal's content.
            let modalFooterLink = unlinkModal.querySelector('.modal-footer a')
            let modalBodyInput = unlinkModal.querySelector('.modal-body h5 span')

            modalBodyInput.innerText = userName
            modalFooterLink.setAttribute('href', Routing.generate('backend_rattachement_reseau_remove_rattachement_client', {'idRattachement' : reseauId ,'idClient': userId}));
        })
    }
    typeChoice(e) {

    }
}
