import { Controller } from 'stimulus';
import { toastGenerator } from '../scripts/toast';
import Swal from "sweetalert2";

export default class extends Controller {
    static values = {
        containerSelector: String,
        url: String,
    }
    container = null;

    connect() {
        this.container = window.document.querySelector(this.containerSelectorValue);
    }

    async create(event) {
        const container = this.container;
        event.preventDefault();
        event.stopPropagation();

        Swal.fire({
            title: 'Chargement...',
            timer: 240000,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: function () {
                Swal.showLoading()
            }
        }).then(function (result) {
            if (result.dismiss === 'timer') {
                Swal.fire("Echec", "Le temps de chargement est anormalement long.<br/>Vous pouvez signaler ce problème via le formulaire de demande d\'aide afin que le problème soit résolu dans les meilleurs délais.", "error");
            }
        });

        const params = new URLSearchParams({
            ajax: 1,
        });
        const response = await fetch(this.urlValue, {
            method: 'POST',
            body: params,
        })
        if (response.ok) {
            response.json().then(data => {
                if (data.html) {
                    container.innerHTML = data.html;
                } else {
                    const toastContainer = document.getElementById('toastContainer');
                    let type = 'danger';
                    let message = 'Une erreur est survenue.';
                    if (data.type && data.content) {
                        type = data.type;
                        message = data.content;
                    }
                    toastContainer.insertAdjacentHTML('beforeend', toastGenerator(type, message));
                }
            });
        } else {
            const toastContainer = document.getElementById('toastContainer');
            const type = 'danger';
            const message = 'Une erreur est survenue.';
            toastContainer.insertAdjacentHTML('beforeend', toastGenerator(type, message));
        }
        Swal.close();
    }
}
