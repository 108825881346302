import { Controller } from 'stimulus';
import { Offcanvas } from 'bootstrap';
import { useWindowResize } from 'stimulus-use';

export default class extends Controller {
    static targets = ['offcanvas'];
    offcanvas = null;

    connect() {
        useWindowResize(this);
        this.offcanvas = Offcanvas.getOrCreateInstance(this.offcanvasTarget);
        this.toggleMyOffcanvas();
        this.highlightNav();
    }

    windowResize() {
        this.toggleMyOffcanvas();
    }

    toggleMyOffcanvas() {
        if (window.innerWidth < 576) {
            // Prevent hiding animation triggering if page first loaded in mobile view
            this.offcanvasTarget.style.visibility = 'hidden';

            if (this.offcanvasTarget.classList.contains('show')) {
                this.offcanvasTarget.style.visibility = 'hidden';
                this.offcanvasTarget.classList.remove('show');
            }
        } else {
            if (!this.offcanvasTarget.classList.contains('show')) {
                this.offcanvasTarget.style.visibility = 'visible';
                this.offcanvasTarget.classList.add('show');
            }
        }
    }

    highlightNav() {
        var paths = location.pathname.split("/"); // uri to array
        paths.shift(); // Remove domain name
        paths = '/' + paths.join('/'); // Add leading slash and join into a string
        paths = (paths === '/') ? '/' : paths.replace(/\/$/, ""); // Remove trailing slash if present
        const menuItem = document.querySelector('.offcanvas-body a[href="' + paths + '"]');
        if (menuItem) {
            menuItem.classList.add('active');
        }
    }
}
