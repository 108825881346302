import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['clientName','lastname','firstname'];

    connect() {
        this.clientNameTarget.removeAttribute('required');
    }

    validation(e) {
        e.preventDefault();
        this.clientNameTarget.value =  this.firstnameTarget.value + ' ' + this.lastnameTarget.value.toUpperCase() ;
        this.element.submit()
    }
}
