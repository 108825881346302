import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['dropdownMenu', 'searchInput'];

    initialize() {
        this.filter();
    }

    filter() {
        let txtValue = null;
        let filter = this.searchInputTarget.value.toUpperCase();
        let a = this.dropdownMenuTarget.getElementsByTagName("a");
        for (let i = 0; i < a.length; i++) {
            txtValue = a[i].textContent || a[i].innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                a[i].style.display = "";
            } else {
                a[i].style.display = "none";
            }
        }
    }
}
