import { Controller } from 'stimulus';
import Swal from "sweetalert2";

export default class extends Controller {
    download(event) {
        event.preventDefault();
        const that = this;

        Swal.fire({
            title: 'Génération du reporting en cours...',
            timer: 240000,
            didOpen: function () {
                Swal.showLoading()
            }
        }).then(function (result) {
            if (result.dismiss === 'timer') {
                Swal.fire("Echec", "Ce reporting est trop lourd à charger.<br/>Vous pouvez signaler ce problème à service-technique@stimactiv.com afin qu'il soit opérationnel dans les meilleurs délais.", "error");
            }
        });

        const href = event.currentTarget.href;

        // TODO - rewrite the code below without jQuery
        $.ajax({
            url: href,
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                data = JSON.parse(data);
                if (data.status === 'ok') {
                    Swal.fire({
                        type: 'success',
                        title: 'Votre reporting est prêt !',
                        confirmButtonText: 'Télécharger le fichier',
                        showCancelButton: false
                    }).then(function () {
                        that.getFile(data);
                    });
                } else {
                    Swal.fire("Echec", data.status, "error");
                }
            }
        });
    }

    makeblob(dataURL) {
        const BASE64_MARKER = ';base64,';
        const parts = dataURL.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: contentType});
    }

    isie() {
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf("MSIE ");

        // If Internet Explorer, return version number
        return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    }

    makeFileDownload(cont, ftype, fname, mime) {
        if (!fname) fname = 'export.' + ftype;
        if (!mime) mime = ftype === 'csv' ? 'text/csv' : 'application/zip'; // or 'application/vnd.ms-excel'

        if (Object.prototype.toString.call(cont) === '[object Blob]'
            && window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(cont, fname);
        } else {
            let downloadLink = document.createElement('a');
            downloadLink.download = fname;
            downloadLink.href = typeof cont === 'string'
                ? 'data:' + mime + ';base64,' + cont
                : window.URL.createObjectURL(cont);
            downloadLink.onclick = function (e) {
                document.body.removeChild(e.target);
            };
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    }

    getFile(data) {
        if (this.isie()) {
            this.makeFileDownload(this.makeblob(data.file), 'xlsx', data.filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        } else {
            // TODO - rewrite the code below without jQuery
            let $a = $("<a>");
            $a.attr("href", data.file);
            $("body").append($a);
            $a.attr("download", data.filename);
            $a[0].click();
            $a.remove();
        }

        return 0;
    }
}
