export function toastGenerator(type, message) {
    return "<div\n" +
        "        data-controller='toast'\n" +
        "        class=\"toast bg-"+type+" mx-auto my-3\"\n" +
        "        role=\"alert\"\n" +
        "        aria-live=\"assertive\"\n" +
        "        aria-atomic=\"true\"\n" +
        "    >\n" +
        "        <div class=\"toast-header bg-transparent text-white\">\n" +
        "            <div class=\"me-auto\">\n" +
        "                "+message+"\n" +
        "            </div>\n" +
        "            <button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"toast\" aria-label=\"Close\"></button>\n" +
        "        </div>\n" +
        "    </div>";
}
