import { Controller } from 'stimulus';
const controller = (function() {
    function LazyController(context) {
        this.__stimulusLazyController = true;
        Controller.call(this, context);
    }
    LazyController.prototype = Object.create(Controller && Controller.prototype, {
        constructor: { value: LazyController, writable: true, configurable: true }
    });
    Object.setPrototypeOf(LazyController, Controller);
    LazyController.prototype.initialize = function() {
        var _this = this;
        if (this.application.controllers.find(function(controller) {
            return controller.identifier === _this.identifier && controller.__stimulusLazyController;
        })) {
            return;
        }
        import('C:\\laragon\\www\\privileges\\assets\\controllers\\frontend\\datatable_controller.js').then(function(controller) {
            _this.application.register(_this.identifier, controller.default);
        });
    }
    return LazyController;
})();
export { controller as default };