import { Controller } from 'stimulus';

export default class extends Controller {

    static  targets = ['formSwitch']

    connect() {
        let formSwitch = this.formSwitchTarget;
        formSwitch.addEventListener('input', (e) => {
            this.element.submit()
        })
    }
}