import { Controller } from 'stimulus';
import LogMain from '../../vendor/stimactiv/log-bundle/assets/scripts/main';

export default class extends Controller {
    static values = {
        logUrl: String,
    }

    initialize() {
        LogMain.init(this.logUrlValue);
    }
}
